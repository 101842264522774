<template>
  <v-card>
    <v-card-title class="text-h6">
      <v-row>
        <v-col cols="6">
          {{ item.company_name }} - {{ item.tracking_id }} - {{ item.code }}
        </v-col>
        <v-col cols="6">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="cancel">
              {{ $t('labels.cancel') }}
            </v-btn>
            <v-btn color="green darken-1" text @click="confirm" v-if="isShowBtnConfirm">
              {{ $t('labels.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card-title>

    <v-col cols="12" v-if="material.status === 0">
      <v-alert color="error" text dense border="left">{{ $t('labels.goods_status_stop') }}</v-alert>
    </v-col>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="2" v-if="material && material.image">
          <ImageViewer :url="material.image" height="auto" width="100%" />
        </v-col>
        <v-col cols="12" :md="material && material.image ? 5 : 6">
          <v-row>
            <v-col cols="3">
              {{ $t('labels.barcode') }}:
            </v-col>
            <v-col cols="9" class="font-weight-bold">
              {{ material.code }}
            </v-col>
            <v-col cols="3">
              {{ $t('labels.goods') }}:
            </v-col>
            <v-col cols="9" class="font-weight-bold">
              {{ material.name }} | {{ material.unit }}
            </v-col>
            <v-col cols="3">
              {{ $t('labels.goods_description') }}:
            </v-col>
            <v-col cols="9" class="font-weight-bold">
              {{ material.description }}
            </v-col>
            <v-col cols="3">
              {{ $t('labels.size') }}:
            </v-col>
            <v-col cols="9" class="font-weight-bold text-uppercase">
              {{ material.size }}
            </v-col>
            <v-col cols="3">
              {{ $t('labels.note') }}:
            </v-col>
            <v-col cols="9" class="font-weight-bold text-uppercase">
              {{ material.note }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" :md="material && material.image ? 5 : 6">
          <v-row>
            <v-col cols="12">
              {{ $t('labels.checked') }}:
              <span class="info--text text-h6">{{ item.receipted_quantity }}</span>
              <span class="text-h6">/</span>
              <span class="error--text text-h6">{{ item.request_quantity }}</span>
            </v-col>

            <v-col cols="4">
              <v-btn color="primary" block style="height: 40px;" @click="printStamp">
                In tem <v-icon>mdi-printer</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="4">
              <v-text-field v-model.number="itemWeight" class="c-input-xs input-required" type="number"
                :label="$t('labels.weight_2')" dense outlined clearable hide-details min="1" ref="inputIndex_1"
                @keyup.enter="nextInputFocus(1)"></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field v-model="itemUidConfirm" class="c-input-xs" type="text" label="UID" dense outlined clearable
                hide-details ref="inputIndex_2" @keyup.enter="nextInputFocus(2)"></v-text-field>
            </v-col>

            <template v-if="material.process_exp_date">
              <v-col cols="3">
                <v-autocomplete v-model="year" :items="yearOptions" class="c-input-xs"
                  :label="$t('labels.expired_year')" dense outlined clearable hide-details ref="inputIndex_3"
                  @change="nextInputFocus(3)"></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete v-model="month" :items="monthOptions" class="c-input-xs"
                  :label="$t('labels.expired_month')" dense outlined clearable hide-details :disabled="!year"
                  ref="inputIndex_4" @change="nextInputFocus(4)"></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete v-model="day" :items="dayOptions" :disabled="!month || !year" class="c-input-xs"
                  :label="$t('labels.expired_day')" dense outlined clearable hide-details ref="inputIndex_5"
                  @change="nextInputFocus(5)"></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="lotTracking" class="c-input-xs" type="text" :label="$t('labels.lot')" dense
                  outlined clearable hide-details ref="inputIndex_6" @keyup.enter="nextInputFocus(6)"></v-text-field>
              </v-col>
            </template>

          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="dialogConfirmRemove" persistent max-width="350px">
      <ConfirmRemoveBox v-if="dialogConfirmRemove" :title="$t('labels.remove_basket')"
        :description="$t('labels.scan_basket_and_confirm_to_remove')" :label="$t('labels.remove_basket_and_confirm')"
        :placeholder="$t('labels.remove_basket_and_confirm')" :codes="removeCodes" @cancel="cancelConfirmRemove"
        @confirm="confirmRemove" />
    </v-dialog>

  </v-card>
</template>

<script>
import checkMaterialMixins from "@/components/material/mixins/checkMaterialMixins";

export default {
  name: "CheckMaterial",
  components: {
    ImageViewer: () => import('@/components/common/ImageViewer'),
    ConfirmRemoveBox: () => import('@/components/common/ConfirmRemoveBox')
  },
  mixins: [checkMaterialMixins]
}
</script>

<style scoped></style>
